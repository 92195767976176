@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";

@font-face {
  font-family: "Meta Pro";
  src: url(FFMetaProRegular.TTF);
}

:root {
  --purple: #532c6d;
  --dark-purple: #371d47;
  --light-purple: rgba(83, 44, 109, 0.1);
  --dark-gray: #333;
  --light-gray: #777;
  --lightest-gray: #c3c3c3;
  --off-white: #f5f5f5;
  margin: 8px;
}

body {
  font-family: "Meta Pro", sans-serif;
  color: var(--dark-gray);
  background-color: var(--off-white);
}

::placeholder {
  font-family: "Meta Pro", sans-serif;
  font-size: 16px;
  color: var(--light-gray);
}
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;  
}
::-moz-placeholder {
  font-style: italic;  
}
:-ms-input-placeholder {  
  font-style: italic; 
}
* {
  outline: 0;
}

input {
  font-family: 'Roboto', sans-serif;
}

img {
  max-width: 100%;
  padding: 8px;
}

main {
  margin-bottom: 50px;
}

header {
  border-bottom: 1px solid var(--lightest-gray);
}

a {
  color: var(--purple);
  cursor: pointer;
}
a:hover,
a:after {
  color: var(--dark-purple);
}

a,
.btn,
.form-control,
.custom-radio.rounded span:before,
.custom-radio.rounded span:after,
.custom-radio.square span:before,
.custom-radio.square span:after,
.custom-switch .slider,
.custom-switch .slider:before,
.tile-btn {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

#MainDiv {
  padding: 0 16px 16px;
  max-width: 650px;
  margin: 35px auto;
  overflow: auto;
  min-height: 300px;
}

h1 {
  margin: 40px 0px;
  padding-left: 1px;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  font-family: "Meta Pro", sans-serif;
  color: var(--purple);
  text-align: center;
}

h2 {
  font-size: 20px;
  color: var(--purple);
}

h3 {
}

h4 {
  margin: 30px 0px;
  color: var(--dark-gray);
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

p {
  color: var(--dark-gray);
}

.modal-open #MainDiv,
.modal-open main {
  filter: blur(5px);
}
.styleguide-icon-btn {
  display: flex;
}
.styleguide-icon-btn .btn + .btn {
  margin-left: 5px;
}
.purple-bg {
  background-color: #7c5b8c;
  padding: 16px;
  margin: 0 -16px;
}

.bc-group {
  width: 95%;
  height: 80px;
  margin: 0 auto;
  padding-top: 40px;
}

.bc-track {
  position: relative;
  top: 17px;
  width: 100%;
  height: 1px;
  background-color: var(--purple);
  z-index: -1;
}

.bcs {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
}

.bc {
  width: 30px;
  list-style: none;
}

.bc-text {
  font-size: 18px;
  line-height: 1.5;
  color: var(--purple);
  text-align: center;
  margin: 0px 0px 0px 0px;
  height: 30px;
  width: 30px;
  border: 1px solid var(--purple);
  border-radius: 20px;
  background-color: var(--off-white);
}

.current-page {
  color: #fff;
  background-color: var(--purple);
}
.bc-caption {
  font-size: 12px;
  text-align: center;
  color: var(--purple);
  margin: 0px auto;
  width: 60px;
  position: relative;
  left: -15px;
}

/* Main title */
.page-title {
  margin: 0 -16px 17px;
  padding: 30px 16px 0px 16px;
  color: var(--purple);
  text-align: center;
}
/*.page-title:before {
  content: "";
  width: 130%;
  height: 125%;
  background: #5f60a9;
  background: linear-gradient(90.19deg, #532c6d 0.04%, #64407b 99.95%);
  border-radius: 50%;
  position: absolute;
  left: -15%;
  right: 0;
  bottom: 0;
  z-index: -1;
}*/
.page-title.lg {
  padding: 37px 23px 0px;
  margin-bottom: 51px;
}
.page-title.lg:before {
  width: 140%;
  left: -20%;
}
.page-title .go-back {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  padding: 3px 0;
  margin-right: 14px;
}
.page-title .go-back:hover {
  opacity: 0.7;
}

.page-title p {
  font-family: "Meta Pro", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.form-group {
  margin-bottom: 30px;
}
.form-group-core-medical-button {
  margin-bottom: 30px;
}
.btn-block.prev-back-btn.form-group-core-medical-button {
  margin-top: 10px;
}
.form-group label {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  color: var(--purple);
}
.form-group .custom-radio-wrapper label {
  margin-bottom: 8px;
}
.form-group label .optional {
  color: var(--light-gray);
}

/* Custom radio-checkbox */
.custom-radio {
  display: inline-block;
  align-items: center;
  position: relative;
  margin-right: 10px;
}
/* .custom-radio + .custom-radio {
  margin-left: 14px;
  margin-bottom: 9px;
} */
.form-group input[type="date"] {
  width: 40%;
}
.custom-radio .custom-input{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  padding: 0;
}
.custom-radio .custom-input:checked + span {
  color: var(--off-white);
  background-color: var(--purple);
}
.custom-radio span {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: var(--purple);
  border: 1px solid var(--purple);
  border-radius: 12px;
  padding: 9px 11px;
  position: relative;
  display: block;
}
.custom-radio.secondary span {
  padding: 0 9px;
  border-radius: 50%;
  width: auto;
  max-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  border: 0;
}
/*.custom-radio.secondary span {
	padding: 3px 4px;
	border-radius: 50%;
	width: auto;
	max-width: 25px;
	min-width: 25px;
	font-weight: 400;
	border: 0;
	display: block;
	text-align: center;
	}*/
.custom-radio.secondary + .custom-radio.secondary {
  margin-left: 20px;
}
.radio-indicators {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  max-width: 482px;
}
.radio-indicators span {
  color: var(--light-gray);
  font-size: 14px;
  line-height: 16px;
  width: 30%;
}
.radio-indicators span:last-child {
  text-align: right;
}

.custom-radio.rounded span {
  padding: 0;
  min-height: 16px;
  min-width: 16px;
  border: none;
  color: var(--dark-gray);
  padding-left: 25px;
  font-weight: 400;
}
.custom-radio.rounded span:before {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 50%;
  left: 0;
  border: 2px solid var(--purple);
}
.custom-radio.rounded span:after {
  content: "";
  position: absolute;
  height: 6px;
  width: 6px;
  background-color: var(--off-white);
  transform: scale(0);
  border-radius: 50%;
  left: 5.1px;
  top: 4.6px;
}
.custom-radio.rounded .custom-input:checked + span {
  color: var(--dark-gray);
  background-color: transparent;
  border-color: transparent;
}
.custom-radio.rounded .custom-input:checked + span:before {
  background-color: var(--purple);
}
.custom-radio.rounded .custom-input:checked + span:after {
  transform: scale(1);
}

.custom-radio.square span {
  padding: 0;
  min-height: 18px;
  min-width: 18px;
  border: none;
  color: var(--dark-gray);
  padding-left: 25px;
  font-weight: 400;
}
.custom-radio.square span:before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 3px;
  left: 0;
  border: 2px solid var(--purple);
}
.custom-radio.square span:after {
  content: "";
  position: absolute;
  height: 9px;
  width: 12px;
  background-image: url(./images/tick.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  transform: scale(0);
  border-radius: 3px;
  left: 3px;
  top: 4px;
}
.custom-radio.square .custom-input:checked + span,
.custom-radio.square .custom-input:indeterminate + span {
  color: var(--dark-gray);
  background-color: transparent;
  border-color: transparent;
}
.custom-radio.square .custom-input:checked + span:before,
.custom-radio.square .custom-input:indeterminate + span:before {
  background-color: var(--purple);
}
.custom-radio.square .custom-input:checked + span:after {
  transform: scale(1);
}
.custom-radio.square .custom-input:indeterminate + span:after {
  background-image: none;
  width: 10px;
  height: 2px;
  background-color: var(--off-white);
  transform: scale(1);
  top: 8px;
  left: 4px;
}

/* Switch */
.custom-switch {
  position: relative;
  display: inline-block;
  line-height: 20px;
  min-height: 18px;
}
.custom-switch input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.custom-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--off-white);
  border-radius: 36px;
  border: 1px solid var(--light-gray);
  width: 33px;
  height: 18px;
}
.custom-switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  top: 1px;
  background-color: var(--off-white);
  border-radius: 50%;
}
.custom-switch input:checked + .slider {
  background-color: var(--purple);
}
.custom-switch input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  -moz-transform: translateX(15px);
  -o-transform: translateX(15px);
  transform: translateX(15px);
}

/* Input & Dropdowns styling */
/*from2.8*/
.form-control {
  width: 100%;
  height: 42px;
  padding: 6px 8px;
  background-color: var(--off-white);
  border: 1px solid var(--lightest-gray);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control,
output {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--dark-gray);
}

/*from app*/
.form-control:focus {
  border-color: var(--purple);
  outline: 0;
  box-shadow: none;
}
textarea.form-control {
  height: auto;
  min-height: 60px;
}
.form-control.custom-dropdown {
  -webkit-appearance: none;
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
  padding-right: 40px;
}

.btn-block.prev-back-btn {
  margin-top: 33px;
  display: flex;
  justify-content: center;
}
/*.btn-block.prev-back-btn .btn-outline-primary { width: calc(36% - 8px); }
.btn-block.prev-back-btn .btn-primary { width: calc(64% - 8px); }*/
.btn-block.prev-back-btn .btn {
  min-width: 172px;
}
.btn-block.prev-back-btn .btn + .btn {
  margin-left: 16px;
}

.btn-block.prev-back-btn.has-form-forms.text-center {
  display: block;
  width: 498px;
}

/*Buttons*/
/* .btn { border-radius: 12px; font-size: 16px; line-height: 20px; font-weight: 700; padding: 9px 18px; border: 3px solid transparent; position: relative; z-index: 1; -webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0); } */

.btn {
  cursor: pointer;
  font-family: inherit;
  height: 42px;
  border-radius: 21px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}
/* .btn:after { content: ''; position: absolute; z-index: -1; border-radius: 6px; top: 0; left: 0; right: 0; bottom: 0; -webkit-transform: scaleX(0); transform: scaleX(0); -webkit-transform-origin: 0 50%; transform-origin: 0 50%; -webkit-transition-property: transform; transition-property: transform; -webkit-transition-duration: 0.3s; transition-duration: 0.3s; -webkit-transition-timing-function: ease-out; transition-timing-function: ease-out; } */
/* .btn:hover:after, .btn:focus:after, .btn:active:after { -webkit-transform: scaleX(1); transform: scaleX(1); } */
/* .btn:focus { box-shadow: none !important; } */
.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}
.btn.disabled:after,
.btn:disabled:after {
  display: none;
}

/* Primary button */
.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 12px;
}

.btn-primary {
  background-color: var(--purple);
  border: 0;
  overflow: hidden;
  color: var(--off-white);
}
.btn-primary:after {
  background: var(--dark-purple);
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--dark-purple);
}
/* .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle { color: #fff; background-color: #E65526; border-color: #E65526; } */
/* .btn-primary.disabled, .btn-primary:disabled { color: #fff; background-color: #E65526; border-color: #E65526; } */

/* Secondary/outline button */
.btn-outline-primary {
  color: var(--purple);
  border-color: var(--purple);
  background-color: var(--off-white);
}
.btn-outline-primary:after {
  background: var(--light-purple);
}
.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: var(--light-purple);
}
/* .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle { color: #fff; background-color: transparent; border-color: #E65526; } */
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--dark-purple);
  border-color: var(--dark-purple);
  background-color: transparent;
}

/* Light button */
.btn-light {
  color: var(--purple);
  background-color: var(--off-white);
  border: 0;
  overflow: hidden;
}
.btn-light:after {
  background: var(--light-purple);
}
.btn-light:hover,
.btn-light:focus {
  color: var(--purple);
  background-color: var(--off-white);
  border-color: var(--off-white);
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #7c5b8c;
  background-color: var(--off-white);
  border-color: #e2e6ea;
}
.btn-light.disabled,
.btn-light:disabled {
  color: #7c5b8c;
  border-color: var(--off-white);
  background-color: var(--off-white);
}

/* List button */
.list-btn {
  width: 90px;
  display: flex;
  justify-content: center;
}

.btn-list {
  border-radius: 0px;
  padding: 8px 2px;
  transition: all 0.3s;
  height: 28px;
  color: var(--purple);
  background-color: var(--off-white);
  border-bottom: solid 1px transparent;
  overflow: hidden;
}
.btn-list:after {
  background: var(--light-purple);
}
.btn-list:hover,
.btn-list:focus {
  color: var(--purple);
  border-bottom-color: var(--purple);
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #7c5b8c;
  background-color: var(--off-white);
  border-color: #e2e6ea;
}
.btn-light.disabled,
.btn-light:disabled {
  color: #7c5b8c;
  border-color: var(--off-white);
  background-color: var(--off-white);
}

/* Light outline button */
.btn-outline-light {
  color: var(--off-white);
  border-color: var(--off-white);
}
.btn-outline-light:after {
  background: var(--off-white);
}
.btn-outline-light:hover,
.btn-outline-light:focus {
  color: #7c5b8c;
  background-color: transparent;
  border-color: var(--off-white);
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #7c5b8c;
  background-color: transparent;
  border-color: var(--off-white);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: var(--off-white);
  border-color: var(--off-white);
  background-color: transparent;
}

/* Icon button */
.btn.icon-btn {
  height: 28px;
  width: 28px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn.icon-btn:after {
  background-color: var(--off-white);
  transform: scale(0);
  transform-origin: center;
  -webkit-transform-origin: center;
  border-radius: 50%;
}
.btn.icon-btn:hover:after,
.btn.icon-btn:focus:after {
  transform: scale(1);
}

.btn-link {
  color: #373688;
  border-radius: 12px;
  padding: 3px 5px;
  text-decoration: none;
  overflow: hidden;
}
.btn-link:after {
  background-color: var(--off-white);
}
.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
}
#plus.btn.btn-primary.modal-btn {
  font-size: 45px;
}
/* Modal */
.modal.show {
  display: block;
}
.modal-backdrop {
  display: none;
}
.modal-backdrop.show {
  display: block;
}
.modal-body {
  min-height: 310px;
  position: relative;
  padding: 24px 16px 16px;
}
.modal-close {
  position: absolute;
  right: 11px;
  top: 11px;
}
.modal-content {
  border-radius: 12px;
}
.modal h2 {
  font-family: "Meta Pro", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #373688;
  margin-bottom: 15px;
}
.tempo-input-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 3rem 0 1rem 0;
}
.tempo-input-row .tempo-label span:first-child {
  font-size: 2rem;
}
button.btn-circle {
  border-radius: 50%;
  display: -ms-grid;
  display: grid;
  place-content: center;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  cursor: pointer;
  border: 0;
  padding: 0.5rem;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
button.btn-circle:hover {
  background-color: var(--purple);
  -webkit-box-shadow: -1px -1px 3px #595959, 1px 1px 3px #393939;
  box-shadow: -1px -1px 3px #595959, 1px 1px 3px #393939;
  color: var(--off-white);
}
/* Range Slider */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  margin: 1rem 0 2rem 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 5px;
  background-color: #424242;
  box-shadow: -3px -3px 5px #595959 inset, 3px 3px 5px #393939 inset;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--purple);
  /* -webkit-box-shadow: -3px -3px 5px #595959 inset, 3px 3px 5px #393939 inset;
  box-shadow: -3px -3px 5px #595959 inset, 3px 3px 5px #393939 inset; */
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--purple);
  /* box-shadow: -3px -3px 5px #595959 inset, 3px 3px 5px #393939 inset; */
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  border-color: transparent;
  border-width: 0 0;
  border-radius: 5px;
  background-color: var(--purple);
  /* box-shadow: -3px -3px 5px #595959 inset, 3px 3px 5px #393939 inset; */
}

input[type="range"]::-ms-fill-lower {
  border: 0;
  border-radius: 0;
  background-color: #424242;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #424242;
}

input[type="range"]::-ms-fill-upper {
  border: 0;
  border-radius: 0px;
  background-color: #424242;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #424242;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0;
  width: 2rem;
  height: 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  margin-top: -4px;
  background: var(--off-white);
  -webkit-box-shadow: 0 1px 4px #595959, 0 0 40px #393939;
  box-shadow: 0 1px 4px #595959, 0 0 40px #393939;
}

input[type="range"]::-moz-range-thumb {
  border: 0;
  width: 2rem;
  height: 1.5rem;
  border-radius: 1rem;
  margin-top: -4px;
  background: var(--off-white);
  box-shadow: 0 1px 4px #595959, 0 0 40px #393939;
}

input[type="range"]::-ms-thumb {
  border: 0;
  width: 2rem;
  height: 1.5rem;
  border-radius: 1rem;
  margin-top: -4px;
  cursor: pointer;
  background: var(--off-white);
  box-shadow: 0 1px 4px #595959, 0 0 40px #393939;
}
@media (max-width: 767px) {
 .mobile-review-screen{
  display: none;
  }
}
.review-screen-container>div{
display: inline-block;
vertical-align: top;
margin-left: 2em;
}
.reviewFlexRow:first-child{
font-weight: bold;
}
.reviewFlexRow{
  display: flex;
  border-bottom: solid 1px var(--lightest-gray);
  height: 57.34px;
  align-items: center;
}
.reviewFlexRow>div{
  width: 8em;
  padding: 0.5em;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
.reviewFlexRow>div:last-child{
  width:7.2em;
}
/* End Range Slider */
/* Tiles 
.tile {
  padding: 4px 10px;
  display: block;
  max-width: 100%;
  border-radius: 11px;
  position: relative;
  z-index: 1;
  color: var(--off-white);
}
.tile * {
  color: var(--off-white);
}
.tile h2 {
  font-family: "Meta Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.tile h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 3px;
}

.tile.type1 {
  width: 337px;
  min-height: 212px;
  background: linear-gradient(79.86deg, #00b7fb 3.18%, #b3eaff 91.27%);
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
}
.tile.type1:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: url(./images/character.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: calc(100% - 9px) calc(100% - 7px);
  z-index: -1;
}
.tile.type1 .tile-content {
  max-width: 110px;
}
.tile.type1 .btns {
  display: flex;
  margin-bottom: 7px;
}
.tile.type1 .tile-btn {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  line-height: 0;
  outline: none;
}
.tile.type1 .tile-btn:hover {
  opacity: 0.7;
}
.tile.type1 .tile-btn + .tile-btn {
  margin-left: 6px;
}
.tile.type1 p {
  font-size: 14px;
  line-height: 16px;
}

.tile.type2 {
  background: linear-gradient(
    119.88deg,
    #5f60a9 3.87%,
    rgba(95, 96, 169, 0.72) 128.77%
  );
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  width: 344px;
}
.tile.type2 h2 {
  display: flex;
  justify-content: space-between;
  margin: 2px 0 4px;
}
.tile.type2 h2 .chat-img {
  margin: 3px 25px 0 0;
}
.tile .chatbot {
  display: flex;
  margin-bottom: 9px;
}
.tile .chatbot .dp {
  width: 47px;
  min-width: 47px;
}
.tile .chatbot .text {
  padding-left: 0;
  width: calc(100% - 47px);
}
.tile .chatbot .text p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.tile.type3 {
  padding: 8px 10px 45px;
  width: 165px;
  background: linear-gradient(103.41deg, #5f60a9 -102.56%, #70bbe6 177.56%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.tile.type3:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: url(./images/docs.svg);
  background-size: 69px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(100% + 1px);
  z-index: -1;
}

.tile.type4 {
  padding: 34px 17px 30px;
  background: var(--off-white);
  border-radius: 0;
  width: 375px;
}
.tile.type4 .chat-outer {
  margin-bottom: 15px;
}
.tile.type4 .chat-outer .date {
  font-size: 14px;
  line-height: 16px;
  color: #562a6b;
  display: block;
  padding-left: 6px;
  margin-bottom: 4px;
}
.tile.type4 .chat-outer p {
  background: var(--off-white);
  border-radius: 11px;
  padding: 7px 12px 7px 6px;
  font-size: 16px;
  line-height: 20px;
  color: #562a6b;
  display: inline-block;
  margin: 0;
}
.tile.type4 .chat-outer.reverse {
  text-align: right;
}
.tile.type4 .chat-outer.reverse .date {
  padding: 0 3px 0 0;
}
.tile.type4 .chat-outer.reverse p {
  background: #5f60a9;
  color: var(--off-white);
  text-align: left;
  padding: 7px 6px 7px 9px;
  max-width: 78%;
}
*/

.has-form {
  max-width: 360px;
  margin: 0 auto;
}
/* .has-form-forms {
  margin-left: 60px;
  margin-right: 60px;
} */

/* 
@media(max-width: 1199px) {
	.container { max-width: 100%; }
}

@media(max-width: 767px) {
	.form-group { margin-bottom: 25px; }
	.custom-radio + .custom-radio { margin-left: 2px; }
}
 */

.headlinetwo {
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.abc {
  padding-left: 2px;
  font-weight: 100;
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  color: var(--dark-gray);
}
.abc > span {
  float: right;
}
.errorMessage {
  color: #d8000c;
  padding-left: 2px;
  font-size: 16px;
}

.create {
  font-size: 16px;
  padding-right: 50px;
  margin-left: 90px;
}

.HomeList {
  font-size: 16px;
  padding-right: 50px;
}

.logout {
  display: block;
  /* width: 131px; */
  background-color: var(--purple);
  border-radius: 12px;
  height: 33px;
  font-size: 16px;
  color: white;
  font-weight: 700;
  border-color: var(--purple);
  box-shadow: none !important;
  border: aliceblue;
  margin-top: 19px;
  margin-right: 34px;
}
.logout:hover {
  color: var(--off-white);
  background-color: #e65526;
  transition: 0.3s;
  border-color: #e65526;
}

#tooltip {
  position: absolute;
  color: rgb(15, 14, 14);
  padding: 10px;
  background: rgb(211, 211, 211);
  border-radius: 2px;
  z-index: 1000;
}

.bodymap {
  padding: 0%;
}

.fullbodyfront {
  background: url("./body-front/body-front.png") no-repeat center center;
  background-size: contain;
  padding: 0 0 153.847%;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.fullbodyback {
  background: url("./body-back/body-back.png") no-repeat center center;
  background-size: contain;
  padding: 0 0 153.847%;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.fullbodyfront a,
.fullbodyback a {
  position: absolute;
  cursor: pointer;
  opacity: 1;
}
.fullbodyfront a:hover,
.fullbodyback a:hover {
  opacity: 1;
}
.fullbodyfront a img,
.fullbodyback a img {
  display: block;
  width: 100%;
}

/* Front Body Part */
.right-head-front {
  right: 49.8%;
  top: 0.3%;
  width: 7%;
}
.left-head-front {
  left: 50.2%;
  top: 0.3%;
  width: 7%;
}
.head-front {
  left: 41.85%;
  top: 5.1%;
  width: 16.5%;
}
.jaw-front {
  left: 44.2%;
  top: 9.5%;
  width: 12%;
}
.cervical-front {
  left: 40.4%;
  top: 13.1%;
  width: 19.5%;
}

.right-chest-front {
  left: 38.01%;
  top: 15.5%;
  width: 12.167%;
}
.left-chest-front {
  left: 50.2%;
  top: 15.5%;
  width: 12.167%;
}
.right-ribs-front {
  left: 36.35%;
  top: 24.1%;
  width: 13.834%;
}
.left-ribs-front {
  left: 50.2%;
  top: 24.1%;
  width: 13.834%;
}
.abdomen-front {
  left: 36.3%;
  top: 30.2%;
  width: 27.667%;
}

.right-shoulder-front {
  left: 29.5%;
  top: 16.5%;
  width: 9.5%;
}
.right-upper-arm-front {
  left: 28%;
  top: 24.1%;
  width: 9.5%;
}
.right-elbow-front {
  left: 24.4%;
  top: 32.4%;
  width: 13%;
}
.right-forearm-front {
  left: 22%;
  top: 37.1%;
  width: 12.5%;
}
.right-wrist-front {
  left: 17.4%;
  top: 46%;
  width: 11%;
}
.right-hand-front {
  left: 19%;
  top: 49%;
  width: 7.667%;
}
.right-thumb-front {
  left: 14.9%;
  top: 49%;
  width: 4.167%;
}
.right-fingers-front {
  left: 15.34%;
  top: 53.2%;
  width: 10.5%;
}

.left-shoulder-front {
  left: 61.4%;
  top: 16.5%;
  width: 9.5%;
}
.left-upper-arm-front {
  left: 62.7%;
  top: 24.1%;
  width: 9.5%;
}
.left-elbow-front {
  left: 62.9%;
  top: 32.4%;
  width: 13%;
}
.left-forearm-front {
  left: 65.9%;
  top: 37.1%;
  width: 12.5%;
}
.left-wrist-front {
  left: 72%;
  top: 46%;
  width: 11%;
}
.left-hand-front {
  left: 73.7%;
  top: 49%;
  width: 7.667%;
}
.left-thumb-front {
  left: 81.4%;
  top: 49%;
  width: 4.167%;
}
.left-fingers-front {
  left: 74.5%;
  top: 53.2%;
  width: 10.5%;
}

.right-pelvis-front {
  left: 35.4%;
  top: 41.2%;
  width: 14.835%;
}
.left-pelvis-front {
  left: 50.2%;
  top: 41.2%;
  width: 14.835%;
}
.right-hip-front {
  left: 34.5%;
  top: 46%;
  width: 15.668%;
}
.left-hip-front {
  left: 50.2%;
  top: 46%;
  width: 15.668%;
}

.right-upper-leg-front {
  left: 32.7%;
  top: 51.8%;
  width: 17.5%;
}
.right-knee-front {
  left: 35.7%;
  top: 66.9%;
  width: 11.667%;
}
.right-lower-leg-front {
  left: 35.2%;
  top: 74.5%;
  width: 12%;
}
.right-ankle-front {
  left: 40%;
  top: 92.1%;
  width: 6.335%;
}
.right-foot-front {
  left: 38%;
  top: 95.7%;
  width: 8.668%;
}
.right-toe-front {
  left: 36.1%;
  top: 98%;
  width: 2%;
}

.left-upper-leg-front {
  left: 50.2%;
  top: 51.8%;
  width: 17.5%;
}
.left-knee-front {
  left: 53%;
  top: 66.9%;
  width: 11.667%;
}
.left-lower-leg-front {
  left: 53.2%;
  top: 74.5%;
  width: 12%;
}
.left-ankle-front {
  left: 53.9%;
  top: 92.1%;
  width: 6.5%;
}
.left-foot-front {
  left: 53.7%;
  top: 95.7%;
  width: 8.668%;
}
.left-toe-front {
  left: 62.4%;
  top: 98%;
  width: 2%;
}

/* Back Body Part */
.right-head-back {
  left: 50.2%;
  top: 0.3%;
  width: 6.834%;
}
.left-head-back {
  left: 43.4%;
  top: 0.3%;
  width: 6.834%;
}
.head-back {
  left: 42.05%;
  top: 4.1%;
  width: 16.335%;
}
.b-cervical {
  left: 44.2%;
  top: 9.4%;
  width: 12.167%;
}
.cervical-back {
  left: 40.3%;
  top: 12.9%;
  width: 20%;
}
.cervicothoracic-back {
  left: 40.3%;
  top: 12.9%;
  width: 20%;
}

.right-thoracic {
  left: 50.2%;
  top: 15.7%;
  width: 12.835%;
}
.central-thoracic {
  left: 45.8%;
  top: 23.7%;
  width: 8.668%;
}
.left-thoracic {
  left: 38%;
  top: 15.7%;
  width: 12.168%;
}
.right-ribs-back {
  left: 54.5%;
  top: 23.7%;
  width: 9.5%;
}
.left-ribs-back {
  left: 36.3%;
  top: 23.7%;
  width: 9.5%;
}
.thoracolumbar {
  left: 36.3%;
  top: 31.5%;
  width: 27.668%;
}
.left-lumbosacral {
  left: 36%;
  top: 36.8%;
  width: 14.169%;
}
.right-lumbosacral {
  left: 50.1%;
  top: 36.8%;
  width: 14%;
}

.right-shoulder-back {
  left: 62%;
  top: 15.7%;
  width: 8.835%;
}
.right-upper-arm-back {
  left: 63%;
  top: 23.7%;
  width: 8.835%;
}
.right-elbow-back {
  left: 63%;
  top: 31.5%;
  width: 12.835%;
}
.right-forearm-back {
  left: 65.7%;
  top: 36.8%;
  width: 12.335%;
}
.right-wrist-back {
  left: 71.9%;
  top: 45.4%;
  width: 11.5%;
}
.right-hand-back {
  left: 73.9%;
  top: 49.1%;
  width: 6.335%;
}
.right-fingers-back {
  left: 74.24%;
  top: 52.3%;
  width: 11%;
}
.right-thumb-back {
  left: 80.2%;
  top: 49.1%;
  width: 5.335%;
}

.left-shoulder-back {
  left: 29.5%;
  top: 15.7%;
  width: 9.5%;
}
.left-upper-arm-back {
  left: 28.5%;
  top: 23.7%;
  width: 8.835%;
}
.left-elbow-back {
  left: 24.7%;
  top: 31.5%;
  width: 12.668%;
}
.left-forearm-back {
  left: 22.3%;
  top: 36.8%;
  width: 12.335%;
}
.left-wrist-back {
  left: 17.1%;
  top: 45.3%;
  width: 11.5%;
}
.left-hand-back {
  left: 20.2%;
  top: 49.1%;
  width: 6.335%;
}
.left-fingers-back {
  left: 15.14%;
  top: 52.3%;
  width: 11%;
}
.left-thumb-back {
  left: 14.85%;
  top: 49.1%;
  width: 5.335%;
}

.right-pelvis-back {
  left: 50.2%;
  top: 42.2%;
  width: 14.835%;
}
.left-pelvis-back {
  left: 35.4%;
  top: 42.2%;
  width: 14.835%;
}
.right-hip-back {
  left: 50.2%;
  top: 47.4%;
  width: 15.5%;
}
.left-hip-back {
  left: 34.8%;
  top: 47.4%;
  width: 15.5%;
}

.right-upper-leg-back {
  left: 50.1%;
  top: 51%;
  width: 17.335%;
}
.right-knee-back {
  left: 53.2%;
  top: 67.7%;
  width: 11.335%;
}
.right-lower-leg-back {
  left: 53.2%;
  top: 74.9%;
  width: 12%;
}
.right-ankle-back {
  left: 53.9%;
  top: 92.3%;
  width: 6.5%;
}
.right-foot-back {
  left: 53.7%;
  top: 96.7%;
  width: 6.5%;
}
.right-toe-back {
  left: 60.1%;
  top: 96.6%;
  width: 4.168%;
}

.left-upper-leg-back {
  left: 32.9%;
  top: 51%;
  width: 17.335%;
}
.left-knee-back {
  left: 36%;
  top: 67.7%;
  width: 11.335%;
}
.left-lower-leg-back {
  left: 35.4%;
  top: 74.9%;
  width: 12%;
}
.left-ankle-back {
  left: 40%;
  top: 92.3%;
  width: 6.5%;
}
.left-foot-back {
  left: 40.1%;
  top: 96.7%;
  width: 6.5%;
}
.left-toe-back {
  left: 36%;
  top: 96.6%;
  width: 4.168%;
}

.text-center {
  text-align: center;
  align-items: center;
}
.list-group-item > div {
  display: flex;
  justify-content: space-between;
}
.list-group-item > div button {
  display: block;
  margin-left: 5px;
  min-width: 28px;
  cursor: pointer;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  border: 1px solid #a9a9a9;
  border-radius: 4px;
}

@media (max-width: 1000px) {
  .container {
    max-width: 100%;
  }
  .form-group label {
    padding-left: 2px;
    margin-top: 10px;
    line-height: 20px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
  .head {
    padding: 0%;
    margin-left: -33%;
  }
  .shoulderRegion {
    padding: 0%;
    margin-left: -88px;
  }
  /*
  .has-form-forms {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  */
  .topheadfront {
    margin: 0px 0% 0px -33%;
  }
  .login {
    text-align: center;
  }
  .btn-block.prev-back-btn.has-form-forms.text-center {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #MainDiv {
    max-width: inherit;
    margin: 20px;
  }
  /*
  .has-form-forms {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  */
  .abc {
    display: block;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .fullbodyfront,
  .fullbodyback {
    padding: 0 0 166.67%;
    max-width: inherit;
  }
  #MainDiv .inner-content-wrapper {
    padding: 0 15px 30px;
  }
}

@media (max-width: 420px) {
  .custom-radio.rounded {
    display: flex;
  }
  .custom-radio.rounded + .custom-radio.rounded {
    margin: 16px 0 0;
  }
  .btn-block.prev-back-btn .btn {
    min-width: 120px;
  }
}

#homepageCards {
  padding: 0 16px 16px;
  max-width: 336px;
  margin: 35px auto;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  float: auto;
}

#titleCrds {
  position: absolute;
  width: 337.28px;
  height: 57.34px;
  left: 402.09px;
  top: 127px;

  background: linear-gradient(89.14deg, #532c6d 7.77%, #64407b 91.15%);
  border-radius: 12px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.todoList {
  height: 57.34px;
  border-bottom: solid 1px var(--lightest-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin: 24px 0px;
}

.e-btn-icon.e-image {
  float: right;
  background: url(./UserIco.png);
  width: 29px;
  height: 29px;
}

/* .e-button-corner {
  border-radius: 50px;
} */

.loading-circle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.top-horizontal-line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgb(207, 207, 207);
  border-width: 0;
  margin-top: 9px;
}

.e-popup.e-popup-open {
  background-color: whitesmoke;
}

.wha-logo {
  margin-top: 10px;
  margin-left: 5px;
  box-sizing: content-box;
  height: 50.07px;
}

.landing-page-div {
  text-align-last: center;
}
.landing-page-div > img {
  max-height: 150px;
  margin-bottom: 40px;
}
.landing-page-heading {
  text-align: center;
}

.landing-page-login {
  margin-top: 60px;
}

.login-page-login {
  margin-top: 24px;
}
.landing-page-heading > img {
  height: 150px;
}
.forg {
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
}

#multiple-select-square-checkbox td{
  display: inline-block;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
/* input::-webkit-calendar-picker-indicator{
  display: none;
}
input[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
} */
.bodyImageClassName .Dropdown-control{
  border-radius: 8px;
  background-color: var(--off-white);
}
.add-body-part{
  display: flex;
  justify-content: end;
  color: red;
  margin-top: 10px;
  font-size: 16px;
}
.div-progress-bar >p{
  text-align: center;
}